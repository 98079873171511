<template>
  <!-- <div class="bg" @click="onCouponClick">
    <img class="photo" src="@/assets/profilepic.png" alt="" />
    <span class="description">ครบ 5 ดวง ฟรีขนม มูลค่าไม่เกิน 65 บาท 1 ชิ้น</span>
    <br />
    <span class="dateGet">{{ getCreatedDate }}</span>
  </div> -->

  <el-row>
    <el-col :span="1" />
    <el-col :span="22">
      <el-card :body-style="{ padding: '0px' }">
        <!-- <img src="@/assets/img/" class="image"> -->
        <!-- <img src="@/assets/coupons/free01_400x200.jpg" class="image"> -->
        <img :src="getImageSrc" class="image" />
        <div class="description">
          <label>ฟรีขนม มูลค่าไม่เกิน 65 บาท 1 กล่อง</label>
          <div>
            <span
              ><time class="time">{{ getCreatedDate }}</time></span
            >
          </div>
          <div class="bottom">
            <span></span>
            <el-button v-if="showButton" type="primary" @click="onCouponClick">ใช้คูปอง</el-button>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="1" />
  </el-row>
</template>

<script>
import img01 from "../../assets/coupons/free01_800x400.jpg";
import img02 from "../../assets/coupons/free02_800x400.jpg";
import img03 from "../../assets/coupons/free03_800x400.jpg";
import img04 from "../../assets/coupons/free04_800x400.jpg";
import img05 from "../../assets/coupons/free05_800x400.jpg";
import img06 from "../../assets/coupons/free06_800x400.jpg";
import img07 from "../../assets/coupons/free07_800x400.jpg";
import img08 from "../../assets/coupons/free08_800x400.jpg";
import img09 from "../../assets/coupons/free09_800x400.jpg";
import img10 from "../../assets/coupons/free10_800x400.jpg";
export default {
  name: "CouponFreeKanom",
  created() {},
  data() {
    return {
      image: [img01, img02, img03, img04, img05, img06, img07, img08, img09, img10],
    };
  },
  props: { couponObj: Object, onClickedHandle: Function, showButton: Boolean},
  computed: {
    getCreatedDate() {
      if (!this.couponObj) {
        return "Coupon not found!";
      }
      let date = new Date(this.couponObj.data.created._seconds * 1000);
      return date.toLocaleString("th-TH", {
        weekday: "short", // "Sat"
        month: "long", // "June"
        day: "2-digit", // "01"
        year: "numeric", // "2019"
        hourCycle: "h24",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },

    getImageSrc() {
      if(this.couponObj && this.couponObj.id) {
        let hash = this.generateHash(this.couponObj.data.imageTag);
        const modValue = this.image.length;
        let index = Math.abs(hash % modValue);
        return this.image[index];
      }
      return null;
    },
  },
  
  methods: {
    onCouponClick() {
      this.onClickedHandle(this.couponObj);
    },

    generateHash(string) {
      var hash = 0;
      if (string.length == 0) return hash;
      for (let i = 0; i < string.length; i++) {
        var charCode = string.charCodeAt(i);
        hash = (hash << 7) - hash + charCode;
        hash = hash & hash;
      }
      return hash;
    },
  },
};
</script>

<style scoped>
div.bg {
  padding-top: 20px;
  background: lightskyblue;
  width: auto;
  height: 200px;
  margin: 10px 10px 10px 10px;

  /* background: linear-gradient(lightskyblue 49%, transparent 0%), linear-gradient(-45deg, white 33%, transparent 0%) 0 50%, white linear-gradient(45deg, white 33%, lightskyblue 33%) 0 50%;
  background-repeat: repeat-x;
  background-size: 1px 100%, 40px 40px, 40px 40px; */

  /* background: 
linear-gradient(135deg, #42a5e2 25%, transparent 25%) -10px 0,
linear-gradient(225deg, #42a5e2 25%, transparent 25%) -10px 0,
linear-gradient(315deg, #42a5e2 25%, transparent 25%),
linear-gradient(45deg, #42a5e2 25%, transparent 25%);	
background-size: 20px 20px;
background-color: lightskyblue; */

  color: white;
  background-color: #66a3ff;

  background-image: repeating-linear-gradient(transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0, 0, 0, 0.5) 116px, rgba(0, 0, 0, 0.5) 166px, rgba(255, 255, 255, 0.2) 166px, rgba(255, 255, 255, 0.2) 169px, rgba(0, 0, 0, 0.5) 169px, rgba(0, 0, 0, 0.5) 179px, rgba(255, 255, 255, 0.2) 179px, rgba(255, 255, 255, 0.2) 182px, rgba(0, 0, 0, 0.5) 182px, rgba(0, 0, 0, 0.5) 232px, transparent 232px), repeating-linear-gradient(270deg, transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0, 0, 0, 0.5) 116px, rgba(0, 0, 0, 0.5) 166px, rgba(255, 255, 255, 0.2) 166px, rgba(255, 255, 255, 0.2) 169px, rgba(0, 0, 0, 0.5) 169px, rgba(0, 0, 0, 0.5) 179px, rgba(255, 255, 255, 0.2) 179px, rgba(255, 255, 255, 0.2) 182px, rgba(0, 0, 0, 0.5) 182px, rgba(0, 0, 0, 0.5) 232px, transparent 232px);
}

img.photo {
  padding-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border-radius: 50%;
}

/* span.description {
  margin-top: 30px;
  display: block;
  color: white;
  font-weight: bold;
} */

span.dateGet {
  margin-top: 10px;
  display: block;
  color: white;
}

div.description {
  padding: 14px;
  background-color: rgb(255, 255, 255);
}

label {
  color: rgb(160, 95, 10);
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

/* TEST */

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
</style>
