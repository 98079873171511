import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";
// import { Button, Alert, Switch } from 'equal-vue'
// import 'equal-vue/dist/equal.css'
import "./assets/global.css";
import store from "./store";
import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

// Create a new store instance.
// const store = createStore({
//   state() {
//     return {
//       count: 0,
//       customer: { id: null, data: { name: "KKK", point: 0 } },
//     };
//   },
//   mutations: {
//     increment(state) {
//       state.count++;
//     },

//     updateCustomer(state, customer) {
//       state.customer = customer;
//     },
//   },

//   actions: {
//     increment({commit}) {
//       commit("increment");
//     },
//   },
// });

createApp(App)
  .use(Equal)
  .use(router)
  .use(store)
  .use(ElementPlus)
  .mount("#app");
