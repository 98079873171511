import liff from "@line/liff";
import { LIFF_CHANNEL_ID, LIFF_ID } from "../../line/line-config";
import * as slrCrmFunc from '../../composables/slrcrm-cloud-functions';

const state = {
    customer:{data: {picture: 'xxx.jpg', name:'Loading...'}}, 
    pointData:{}, 
    isShowNewPoint: false, 
    coupons:[],
    couponToUse: null,
};

const getters = {
    customer: state => state.customer,
    pointData: state => state.pointData,
    isShowNewPoint: state => state.isShowNewPoint,
    coupons: state => state.coupons,
    couponToUse: state => state.couponToUse,
};

const actions = {
    async updateLineProfileToDBByLineIdToken({commit}, lineTokenId) {
        const resCustomer = await slrCrmFunc.updateLineProfileToDBByLineIdToken(lineTokenId);
        await commit("setCustomer", resCustomer);
    },

    async getPointDataByCustomerUid({commit}, customerUid) {
        const pointData = await slrCrmFunc.getPointDataByCustomerUid(customerUid);
        await commit("setPointData", pointData);
        const updateCustomer = state.customer;
        updateCustomer.data.point = pointData.currentPoint;
        await commit("setCustomer", updateCustomer);
    },

    async setIsShowNewPoint({commit}, isShowNewPoint) {
        await commit("setIsShowNewPoint", isShowNewPoint);
    },

    async getCoupons({commit}, lineTokenId) {
        const couponArray = await slrCrmFunc.getCouponsByLineIdToken(lineTokenId);
        // console.log(couponArray);
        await commit('setCoupons', couponArray);
    },

    async getCouponToUse({commit}, params) {
        const couponToUse = await slrCrmFunc.getCouponByUid(params.lineTokenId, params.couponUid);
        // console.log('CouponToUse0:', couponToUse);
        await commit("setCouponToUse", couponToUse);
    }
};

const mutations = {
    setCustomer: (state, customer) => (
        state.customer = customer
    ),

    setPointData: (state, pointData) => {
        state.customer = {...state.customer, point: pointData.currentPoint};
        state.pointData = pointData;
    },

    setIsShowNewPoint: (state, isShowNewPoint) => {
        state.isShowNewPoint = isShowNewPoint;
    },

    setCoupons: (state, coupons) => {
        state.coupons = coupons.data;
    },

    setCouponToUse: (state, couponToUse) => {
        state.couponToUse = couponToUse.data;
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}