<template>
  <div v-for="coupon in coupons" :key="coupon.id">
    <CouponHolder v-bind:couponObj="coupon" :onClicked="goToCouponUse" :isShowButton="true" />
    <!-- <CouponFreeKanom :onClickedHandle="test" v-bind:couponObj="coupon" v-if="coupon.data.type == '5'"/> -->
    <!-- <Coupon250B :onClickedHandle="test"  v-bind:couponObj="coupon" v-else-if="coupon.data.type == '10'" /> -->
    <!-- <span v-else>คูปองระบุประเภทไม่ได้</span> -->
  </div>
</template>

<script>
import liff from "@line/liff";
import CouponFreeKanom from "@/components/coupons/CouponFreeKanom.vue";
import Coupon250B from "@/components/coupons/Coupon250B.vue";
import CouponHolder from "@/components/coupons/CouponHolder.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MemberCouponList",
  components: {
    CouponHolder
    // CouponFreeKanom, Coupon250B,
  },
  created() {
    
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["coupons", "couponToUse"]),
  },
  props: {},
  methods: {

    ...mapActions(['getCouponToUse']),

    goToCouponUse(couponId) {
      this.$Loading.start();
      const params = {lineTokenId: liff.getIDToken(), couponUid: couponId};
      this.getCouponToUse(params)
        .then(()=>{
          this.$Loading.update(40);
          this.$router.push({ name: "MemberUseCoupon" });
          this.$Loading.finish();
        });
    },
  },
};
</script>

<style  scoped></style>