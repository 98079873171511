import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MemberHome from '../views/MemberHome.vue';
import MemberCard from '../components/MemberCard.vue';
import MemberRegister from '../components/MemberRegister.vue';
import MemberGetPoint from '../components/MemberGetPoint.vue';
import MemberUseCoupon from '../components/MemberUseCoupon.vue';
import MemberTransferPoint from '../components/MemberTransferPoint.vue';
import MemberCouponList from '@/views/MemberCouponList.vue';
import SplashScreen from '../views/SplashScreen.vue';


const routes = [
  
  {
    path: '/',
    name: 'SplashScreen',
    component: SplashScreen
  },
  {
    path: '/MemberHome',
    name: 'MemberHome',
    component: MemberHome
  },
  {
    path: '/register',
    name: 'MemberRegister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: MemberRegister
  },
  {
    path: '/getPoint',
    name: 'MemberGetPoint',
    component: MemberGetPoint,
    props: true
  },
  {
    path: '/couponList',
    name: 'MemberCouponList',
    component: MemberCouponList,
    props: true
  },
  {
    path: '/useCoupon',
    name: 'MemberUseCoupon',
    component: MemberUseCoupon,
    props: true
  },
  {
    path: '/transferPoint',
    name: 'MemberTransferPoint',
    component: MemberTransferPoint,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history:
  routes
})

export default router
