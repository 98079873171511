<template>
  <div class="container">
    <it-progressbar class="vertical-center" infinite />
  </div>
  
</template>

<script>
export default {
  name: "SplashScreen",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
.container {
  height: 500px;
  position: relative;
  /* border: 3px solid green; */
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  
  /* -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}
</style>