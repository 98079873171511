<template>
  <!-- <div class="cardHeader">
    <table class="cardHeader">
      <tr>
        <td class="cardHeaderLeft">
          <img class="profileImage" ref="profileImage" src="" alt="" />
        </td>
        <td class="cardHeaderMid">{{ customer.data.name }}</td>
        <td class="cardHeaderRight">
          <img class="cardHeaderRight" src="@/assets/bkllogotext120x40.png" />
        </td>
      </tr>
    </table> -->
    <el-row class="el-row-header">
      <el-col :span="4"><img class="profileImage" ref="profileImage" src="" alt="" /></el-col>
      <el-col :span="11"><p class="customerName">{{ customer.data.name }}</p></el-col>
      <el-col :span="9"><img class="cardHeaderRight" src="@/assets/bkllogotext240x80.png" /></el-col>
    </el-row>

  <!-- </div> -->
  <div id="card">
    <el-row :gutter="12" class="point-row1">
      <el-col :span="2"></el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[0],divCellStamp: stampPointMarks[0],divCellStampNew: stampPointNew[0],}">
          <p :class="{pCell: !stampPointMarks[0],pCellStamp: stampPointMarks[0],}">1</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[1],divCellStamp: stampPointMarks[1],divCellStampNew: stampPointNew[1],}">
          <p :class="{pCell: !stampPointMarks[1],pCellStamp: stampPointMarks[1],}">2</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[2],divCellStamp: stampPointMarks[2],divCellStampNew: stampPointNew[2],}">
          <p :class="{pCell: !stampPointMarks[2],pCellStamp: stampPointMarks[2],}">3</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[3],divCellStamp: stampPointMarks[3],divCellStampNew: stampPointNew[3],}">
          <p :class="{pCell: !stampPointMarks[3],pCellStamp: stampPointMarks[3],}">4</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[4],divCellStamp: stampPointMarks[4],divCellStampNew: stampPointNew[4],}">
          <p :class="{pCell: !stampPointMarks[4],pCellStamp: stampPointMarks[4],}">Gift</p>
        </div>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>

    <el-row :gutter="12" class="point-row2">
      <el-col :span="2"></el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[5],divCellStamp: stampPointMarks[5],divCellStampNew: stampPointNew[5],}">
          <p :class="{pCell: !stampPointMarks[5],pCellStamp: stampPointMarks[5],}">6</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[6],divCellStamp: stampPointMarks[6],divCellStampNew: stampPointNew[6],}">
          <p :class="{pCell: !stampPointMarks[6],pCellStamp: stampPointMarks[6],}">7</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[7],divCellStamp: stampPointMarks[7],divCellStampNew: stampPointNew[7],}">
          <p :class="{pCell: !stampPointMarks[7],pCellStamp: stampPointMarks[7],}">8</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[8],divCellStamp: stampPointMarks[8],divCellStampNew: stampPointNew[8],}">
          <p :class="{pCell: !stampPointMarks[8],pCellStamp: stampPointMarks[8],}">9</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div :class="{divCell: !stampPointMarks[9],divCellStamp: stampPointMarks[9],divCellStampNew: stampPointNew[9],}">
          <p :class="{pCell: !stampPointMarks[9],pCellStamp: stampPointMarks[9],}">Goal</p>
        </div>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <!-- <div id="stampTable">
      <div
        :class="{
          divCell: !stampPointMarks[0],
          divCellStamp: stampPointMarks[0],
          divCellStampNew: stampPointNew[0],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[0],
            pCellStamp: stampPointMarks[0],
          }"
        >
          1
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[1],
          divCellStamp: stampPointMarks[1],
          divCellStampNew: stampPointNew[1],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[1],
            pCellStamp: stampPointMarks[1],
          }"
        >
          2
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[2],
          divCellStamp: stampPointMarks[2],
          divCellStampNew: stampPointNew[2],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[2],
            pCellStamp: stampPointMarks[2],
          }"
        >
          3
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[3],
          divCellStamp: stampPointMarks[3],
          divCellStampNew: stampPointNew[3],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[3],
            pCellStamp: stampPointMarks[3],
          }"
        >
          4
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[4],
          divCellStamp: stampPointMarks[4],
          divCellStampNew: stampPointNew[4],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[4],
            pCellStamp: stampPointMarks[4],
          }"
        >
          Gift
        </p>
      </div>
    </div> -->

    <!-- <div id="stampTable">
      <div
        :class="{
          divCell: !stampPointMarks[5],
          divCellStamp: stampPointMarks[5],
          divCellStampNew: stampPointNew[5],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[5],
            pCellStamp: stampPointMarks[5],
          }"
        >
          6
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[6],
          divCellStamp: stampPointMarks[6],
          divCellStampNew: stampPointNew[6],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[6],
            pCellStamp: stampPointMarks[6],
          }"
        >
          7
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[7],
          divCellStamp: stampPointMarks[7],
          divCellStampNew: stampPointNew[7],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[7],
            pCellStamp: stampPointMarks[7],
          }"
        >
          8
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[8],
          divCellStamp: stampPointMarks[8],
          divCellStampNew: stampPointNew[8],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[8],
            pCellStamp: stampPointMarks[8],
          }"
        >
          9
        </p>
      </div>
      <div
        :class="{
          divCell: !stampPointMarks[9],
          divCellStamp: stampPointMarks[9],
          divCellStampNew: stampPointNew[9],
        }"
      >
        <p
          :class="{
            pCell: !stampPointMarks[9],
            pCellStamp: stampPointMarks[9],
          }"
        >
          Goal
        </p>
      </div>
    </div> -->
  </div>
  <div class="cardSelect">
    <table class="cardSelect">
      <tr>
        <td class="cardButtonLeft">
          <button @click="changePagePrevious" class="selectCard">&lt;</button>
        </td>
        <!-- <td class="cardNumber">{{ currentCardPage }}/{{ lastCardPage }}</td> -->
        <td class="cardNumber" ref="cardNumber"></td>
        <td class="cardButtonRight">
          <button @click="changePageNext" class="selectCard">&gt;</button>
        </td>
      </tr>
    </table>
  </div>
  <!-- <button @click="testClick">Click</button> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MemberCard",
  props: ["showNewPoint"],
  data() {
    return {
      isInit: false,
      currentCardPage: 0,
      lastCardPage: 0,
      stampPointMarks: [false, false, false, false, false, false, false, false, false, false],
      stampPointNew: [false, false, false, false, false, false, false, false, false, false],
      // isShowNewPoint: false,
    };
  },

  computed: {
    ...mapGetters(["customer", "pointData", "isShowNewPoint"]),
    getPicture() {
      try {
        let picture = this.customer.data.picture;
        // console.log('no error', this.customer);
        return picture;
      }catch(e) {
        console.log("error picture", e);
        return 'xxx';
      }
    },
  },

  watch: {
    customer(newVal, oldVal) {
      if(newVal.data.picture != null) {
        this.$refs.profileImage.src = this.customer.data.picture;
      }
    },
  },

  created() {
    // this.isShowNewPoint = this.showNewPoint;
  },

  mounted() {
    this.$Loading.start();
    const point = this.customer.data.point;
    let pointLastPage = point % 10;
    let lastCardPage = Math.floor(point / 10);
    if (pointLastPage > 0) {
      lastCardPage++;
    }
    this.lastCardPage = lastCardPage;
    this.currentCardPage = lastCardPage;
    if(this.lastCardPage == 0) {
      this.$refs.cardNumber.innerHTML = "1/1";
    }else{
      this.$refs.cardNumber.innerHTML = this.currentCardPage + "/" + this.lastCardPage;
    }
    this.$refs.profileImage.src = this.customer.data.picture;
    this.$Loading.update(20);
    this.renderStamp();
  },

  updated() {
    if(this.lastCardPage == 0) {
      this.$refs.cardNumber.innerHTML = "1/1";
    }else{
      this.$refs.cardNumber.innerHTML = this.currentCardPage + "/" + this.lastCardPage;
    }
  },

  methods: {
    ...mapActions(["getPointDataByCustomerUid"]),

    changePagePrevious() {
      if (this.currentCardPage > 1) {
        this.currentCardPage -= 1;
        this.$refs.cardNumber.innerHTML = this.currentCardPage + "/" + this.lastCardPage;
        this.renderStamp();
      }
    },
    changePageNext() {
      if (this.currentCardPage < this.lastCardPage) {
        this.currentCardPage += 1;
        this.$refs.cardNumber.innerHTML = this.currentCardPage + "/" + this.lastCardPage;
        this.renderStamp();
      }
    },
    renderStamp() {
      this.$Loading.update(40);
      const newPointCount = this.pointData.lastPoint;
      const point = this.customer.data.point;
      let pointLastPage = point % 10;
      if(pointLastPage === 0 && point>0) {
        pointLastPage = 10;
      }

      if (this.currentCardPage < this.lastCardPage) {
        //case not lastpage

        if (newPointCount > pointLastPage) {
          //case new point not only lastpage

          this.stampPointMarks = [true, true, true, true, true, true, true, true, true, true];
          this.stampPointNew = [false, false, false, false, false, false, false, false, false, false];
          if (this.currentCardPage < this.lastCardPage) {
            //case currentPage is not last page

            let pageCountDown = this.lastCardPage - this.currentCardPage;
            let indexSkip = pageCountDown * 10 - (newPointCount - pointLastPage);

            for (let i = indexSkip; i < 10; i++) {
              this.stampPointNew[i] = true && this.isShowNewPoint;
            }
          }
        } else {
          this.stampPointMarks = [true, true, true, true, true, true, true, true, true, true];
          this.stampPointNew = [false, false, false, false, false, false, false, false, false, false];
        }
      } else {
        //case lastpage

        if (pointLastPage == 0 && point > 0) {
          pointLastPage = 10;
        }
        if (pointLastPage > 0) {
          let i = 0;
          this.stampPointMarks = [false, false, false, false, false, false, false, false, false, false];
          this.stampPointNew = [false, false, false, false, false, false, false, false, false, false];

          //render mark point
          for (i = 0; i < pointLastPage; i++) {
            this.stampPointMarks[i] = true;
          }

          //render new point overide
          const newPointCountLastPage = newPointCount;
          let newPointCountdown = newPointCountLastPage;
          for (i = pointLastPage - 1; newPointCountdown > 0; i--) {
            this.stampPointNew[i] = true && this.isShowNewPoint;
            newPointCountdown--;
          }
        }
      }
      this.$Loading.finish();
    },
  },
};
</script>

<style scoped>
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(3deg);
  }
  90% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(5deg);
  }

  /* 0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-5deg); }
  20% { transform: translate(-1px, 0px) rotate(5deg); }
  30% { transform: translate(1px, 0px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(4deg); }
  50% { transform: translate(-1px, 2px) rotate(-3deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(3deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-2deg); } */
}

div.cardSelect {
  background: transparent;
  padding: 0 20px 0 20px;
  height: 50px;
}

table.cardSelect {
  width: 100%;
  height: 100%;
  background: transparent;
}

td.cardButtonLeft {
  text-align: left;
}

td.cardButtonRight {
  text-align: right;
}

button.selectCard {
  /* background: #0b6dff; */
  background: white;
  border: 0;
  color: gray;
  border-radius: 50%;
  width: 45px;
  height: 100%;
  /* border-color: gray; */
  border: 1px solid gray;
  border-radius: 50%;
}

td.cardNumber {
  color: gray;
}

/* .profileImage {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  border-radius: 3px;
  vertical-align: middle;
  object-fit: cover;
  float: inline-end;
} */

.profileName {
  vertical-align: middle;
  margin-left: 10px;
  color: maroon;
  font-size: large;
  font-weight: bolder;
}

.headerHelper1 {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.headerHelper2 {
  height: 100%;
  vertical-align: middle;
  float: right;
}

div.cardHeader {
  width: auto;
  height: 60px;
  display: block;
  background-color: burlywood;
  border-radius: 10px 10px 0 0;
  margin: 0 5px 0 5px;
  text-align: left;
}

table.cardHeader {
  width: 100%;
  height: 100%;
}

td.cardHeaderLeft {
  width: 60px;
  background: transparent;
}

/* td.cardHeaderMid {
  width: auto;
  background: transparent;
  vertical-align: middle;
  margin-left: 10px;
  color: maroon;
  font-size: large;
  font-weight: bolder;
} */

/* td.cardHeaderRight {
  width: 120px;
  background: transparent;
} */

img.cardHeaderRight {
  margin-top: 10px;
  margin-right: 5px;
  vertical-align: middle;
}

#card {
  position: relative;
  background-color: cornflowerblue;
  padding-bottom: 20px;
  padding-top: 5px;
  border-radius: 0 0 10px 10px;
  width: auto;
  z-index: 10;
  text-align: center;
  margin: 0 5px 0 5px;
}

.divCell {
  display: inline-block;
  border: 3px dashed #fff;
  border-radius: 50%;
  background-size: 100% 100%;
  /* height: 55px;
  width: 55px; */
  height: 56px;
  width: 56px;
  /* margin-left: 6px; */
  position: relative;
}

.divCellStamp {
  transform: rotate(0deg);
  display: inline-block;
  background-image: url("../assets/stampbkl100.png");
  background-repeat: no-repeat;
  border: 3px dashed white;
  border-radius: 50%;
  background-size: 100% 100%;
  /* height: 55px;
  width: 55px; */
  height: 56px;
  width: 56px;
  /* margin-left: 10px; */
  /* padding-left: -5px; */
  position: relative;
  z-index: 20;
}



.divCellStampNew {
  /* background-image: url("../assets/stampbkl100.png");
  transform: rotate(0deg);
  display: inline-block;
  background-repeat: no-repeat;
  border: 3px dashed white;
  border-radius: 50%;
  background-size: 100% 100%;
  height: 55px;
  width: 55px;
  margin-left: 6px;
  position: relative;
  z-index: 20; */

  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.pCell {
  vertical-align: middle;
  /* text-align: center; */
  margin-top: 20px;
  color: white;
}

.pCellStamp {
  vertical-align: middle;
  /* text-align: center; */
  margin-top: 20px;
  color: transparent;
  background: transparent;
}

.cssdottedborder {
  width: 150px;
  height: 150px;
  background-color: red;
  border-radius: 50%;
  border: 5px dashed #fff;
  box-shadow: 0 0 0 4px red;
}

#stampTable {
  margin-top: 20px;
  align-content: center;
  /* background-color: yellow; */
}

#stamp {
  display: block;
  width: 100%;
  height: 100%;
}

#cardRow1 {
  background-color: tomato;
  position: relative;
  height: min-content;
  width: 100%;
  margin-top: 20px;
}
#cardRow2 {
  position: relative;
  height: min-content;
  width: 100%;
}

.square {
  height: 50px;
  width: 50px;
  outline: 10px solid transparent;
  background-color: transparent;
  border: 1px solid #1e2023;
  background-image: url("../assets/stamp.png");
  background-size: 100% 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}




/* EL */
.el-row.el-row-header {
  height: 60px;
  background-color: burlywood;
  border-radius: 10px 10px 0 0;
  margin: 0 5px 0 5px;
  /* align:middle; */
}

.profileImage {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  margin-top: 7.5px;
  border-radius: 3px;
  vertical-align: middle;
  /* float: right; */
  object-fit: cover;
  float: inline-end;
}

.customerName{ 
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  color: maroon;
  font-size: large;
  font-weight: bolder;
  margin-left: 8px;
}


.cardHeaderRight {
  width: 115px;
  background: transparent;
  padding: 5px 5px 0 0;
}

.el-row.point-row1 {
  margin-top: 20px;
}

.el-row.point-row2 {
  margin-top: 6px;
}

@media only screen and (max-width: 390px) {
  .divCell {
    height: 50px;
    width: 50px;
  }

  .divCellStamp {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 345px) {
  .divCell {
    display: inline-block;
    border: 3px dashed #fff;
    border-radius: 50%;
    background-size: 100% 100%;
    /* height: 55px;
    width: 55px; */
    height: 45px;
    width: 45px;
    /* margin-left: 6px; */
    position: relative;
  }

  .divCellStamp {
    transform: rotate(0deg);
    display: inline-block;
    background-image: url("../assets/stampbkl100.png");
    background-repeat: no-repeat;
    border: 3px dashed white;
    border-radius: 50%;
    background-size: 100% 100%;
    /* height: 55px;
    width: 55px; */
    height: 45px;
    width: 45px;
    /* margin-left: 6px; */
    position: relative;
    z-index: 20;
  }

  .pCell {
    vertical-align: middle;
    /* text-align: center; */
    margin-top: 15px;
    color: white;
  }
}
</style>
