<template>
  <!-- <div>
        <button @click="genQRClick">GenQR</button>
    </div> -->
  <!-- <it-button @click="$Loading.update(50)">Set 50%</it-button> -->
  <div>
    <label>{{ title }}</label>
  </div>
  <div>
    <!-- <it-loading color="#f93155"></it-loading> -->
    <img id="qrImage" :src="qrUrl" />
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Vue from "vue";
import liff from "@line/liff";
import * as slrCrmFunc from "../composables/slrcrm-cloud-functions";
import { firestore } from "../firebase/config.js";
import QRCode from "qrcode";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MemberGetPoint",
  components: {},
  props: { type: String },
  data() {
    return { title: null, qrCodeTicket: null, qrUrl: null, unsub: null, lastTriggerTime: null };
  },

  created() {
    // this.testVuex();
    if(this.type == "pointGet") {
      this.title = "รับแต้ม";
    }else if(this.type == "pointTransfer") {
      this.title = "โอนแต้ม";
    }
    this.generateQRTicket();
  },

  updated() {},

  unmounted() {
    if (this.unsub instanceof Function) {
      this.unsub();
    }
  },

  computed: {
    ...mapGetters(["customer", "pointData"]),
  },

  methods: {
    ...mapActions(["setIsShowNewPoint", "updateLineProfileToDBByLineIdToken", "getPointDataByCustomerUid"]),

    async updatePointAndRelocate() {
      this.$Loading.update(40);
      await this.getPointDataByCustomerUid(this.customer.id);
      this.$Loading.update(80);
      await this.setIsShowNewPoint(true);
      this.$Loading.finish();
      this.$router.push({ name: "MemberHome" });
    },

    generateQRTicket() {
      this.$Loading.start();
      //props.type = pointGet, pointTransfer
      const objective = { qrFor: this.type, ref: null };
      this.unsub = slrCrmFunc
        .generateCustomerOneTimeQRCode(liff.getIDToken(), objective)
        .then((resData) => {
          this.qrCodeTicket = resData;
          this.$Loading.update(20);
          this.unsub = firestore
            .collection("/SLRCRM/BKLCRM/Triggers/")
            .doc(resData.data.customerUid)
            // .where("unqId", "==", resData.data.customerUid).limit(1)
            .onSnapshot((querySnapshot) => {
              if(this.lastTriggerTime != null) {
                this.lastTriggerTime = null;
                this.$Loading.start();
                this.unsub();
                this.$Loading.update(20);
                this.updateLineProfileToDBByLineIdToken(liff.getIDToken()).then(() => {
                  this.updatePointAndRelocate();
                });  
              }else{
                this.lastTriggerTime = querySnapshot.data().updated;
              }
           
              // querySnapshot.docChanges().forEach((change) => {
              //   if (change.type == "modified") {
              //     this.$Loading.start();
              //     this.unsub();
              //     this.$Loading.update(20);
              //     this.updateLineProfileToDBByLineIdToken(liff.getIDToken()).then(() => {
              //       this.updatePointAndRelocate();
              //     });
              //   }
              // });
            });
          this.renderQRCode();
        })
        .catch((e) => {
          console.log("Error", e);
        });
    },
    renderQRCode() {
      // console.log("HAYTYyYYY");
      this.$Loading.update(60);
      QRCode.toDataURL(this.qrCodeTicket.id, {
        errorCorrectionLevel: "L",
        version: 5,
      })
        .then((url) => {
          // console.log(url);
          this.qrUrl = url;
          this.$Loading.finish();
        })
        .catch((err) => {
          console.error(err);
          this.$Loading.finish();
        });
    },
  },
};
</script>

<style scoped>
#qrImage {
  min-width: 180px;
  min-height: 180px;
}

label {
  color: brown;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
</style>
