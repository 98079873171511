<template>
  <div class="bg">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16"><label>โอนแต้มจากระบบเก่า?</label></el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <div>
      <!-- <div><Label>โอนแต้มจากระบบเก่า</Label></div> -->
      <p>การโอนแต้มจากระบบเก่า สามารถทำได้ครั้งเดียวโดยให้พนักงานขายทำให้เท่านั้น</p>
      <br />
      <p>**คูปองที่ได้รับจากระบบเก่า และยังไม่ได้ใช้ สามารถนำมาใช้ได้ที่สาขาในจังหวัดพัทลุงเหมือนเดิมตามปกติ</p>
      <br />
      <p>**คูปองที่ได้รับจากระบบใหม่ สามารถใช้ได้ทุกสาขา</p>
      <br />
      <br />
      <p>หากเคยเป็นสมาชิกแล้วกด "โอนแต้ม", หากเป็นสมาชิกใหม่กด "สมาชิกใหม่"</p>
      <br />
      <div>
        <el-button @click="onClickTransfer" type="primary" round :loading="isButtonLoading">โอนแต้ม</el-button>
        <el-button @click="onClickIgnoreTransfer" round :loading="isButtonLoading">สมาชิกใหม่</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { mapActions, mapGetters } from "vuex";
import * as slrCrmFunc from "../composables/slrcrm-cloud-functions";
import { firestore } from "../firebase/config.js";
export default {
  name: "MemberTransferPoint",
  created() {},
  data() {
    return { unsub: null, lastTriggerTime: null, isButtonLoading: false };
  },
  props: {},

  computed: {
    ...mapGetters(["customer"]),
  },

  unmounted() {
    if (this.unsub instanceof Function) {
      this.unsub();
    }
  },

  methods: {
    ...mapActions(["updateLineProfileToDBByLineIdToken", "setIsShowNewPoint", "getPointDataByCustomerUid"]),

    onClickTransfer() {
      this.isButtonLoading = true;
      this.$router.push({ name: "MemberGetPoint", params: { type: "pointTransfer" } });
    },

    onClickIgnoreTransfer() {
      this.isButtonLoading = true;
      this.$Loading.start();
      this.$Loading.update(30);
      this.unsub = firestore
        .collection("/SLRCRM/BKLCRM/Triggers/")
        .doc(this.customer.id)
        .onSnapshot((querySnapshot) => {
          if (this.lastTriggerTime != null) {
            this.lastTriggerTimer = null;
            this.updateCustomerPointDataAndRelocationToMemberHome();
          } else {
            this.lastTriggerTime = querySnapshot.data().updated;
          }
        });
      slrCrmFunc.setIsPassPointTransferedProcess(liff.getIDToken(), true, liff.getAccessToken()).then((isSuccess) => {
        if (isSuccess) {
          this.$Loading.update(50);
          this.updateLineProfileToDBByLineIdToken(liff.getIDToken()).then(() => {
            // liff.getFriendship().then((data) => {
            //   // console.log("Is Friend:", data.friendFlag);
            //   if (data.friendFlag) {
            //     //check friendflag in client check is need to add 2 point
            //     // if has no point we will add 2 point
            //     //Register trigger before update point
            //     this.unsub = firestore
            //       .collection("/SLRCRM/BKLCRM/Triggers/")
            //       .doc(this.customer.id)
            //       // .where("unqId", "==", this.customer.id).limit(1)
            //       .onSnapshot((querySnapshot) => {
            //         if(this.lastTriggerTime != null) {
            //           this.lastTriggerTimer = null;
            //           this.updateCustomerPointDataAndRelocationToMemberHome();
            //         }else{
            //           this.lastTriggerTime = querySnapshot.data().updated;
            //         }
            //         // console.log(source, " data: ", querySnapshot.data());
            //         querySnapshot.docChanges().forEach((change) => {
            //           if (change.type == "modified") {
            //             //update Customer for new point
            //             this.updateCustomerPointDataAndRelocationToMemberHome();
            //           }
            //         });
            //       });
            //       // slrCrmFunc.addPointForLineAddFriendFirsttimeIfNeed(liff.getAccessToken(), liff.getIDToken()).then((result) => {
            //       // this.$Loading.update(75);
            //       // });
            //   } else {
            //     //not friend no add any point
            //     this.$Loading.finish();
            //     this.$router.push({ name: "MemberHome" });
            //   }
            // });
          });
        } else {
          // liff.closeWindow();
        }
      });
    },

    async updateCustomerPointDataAndRelocationToMemberHome() {
      this.unsub();
      await this.updateLineProfileToDBByLineIdToken(liff.getIDToken());
      await this.getPointDataByCustomerUid(this.customer.id);
      await this.setIsShowNewPoint(true);
      this.$Loading.update(70);
      this.$Loading.finish();
      this.$router.push({ name: "MemberHome" });
    },
  },
};
</script>

<style scoped>
div.bg {
  max-width: 100%;
  margin: 5px 5px;
  background: wheat;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.row-bg {
  background: transparent;
}

label {
  color: brown;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
</style>
